export const formatAudienceData = (
  value: number | undefined | null,
  isCurrency?: boolean
) => {
  if (value === 0 || value === null || value === undefined) {
    return "-";
  }
  if (isCurrency) {
    return `$${Math.floor(value).toLocaleString()}`;
  }

  return value.toLocaleString();
};
