import {
  Box,
  BoxProps,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { ReportsIconSmall } from "src/components/icons/ReportsIconSmall";
import { ReportItem } from "src/models/Report";
import { EventType } from "src/models/EventType";
import {
  getReportTitle,
  getReportDateTime,
  getReportDescription,
  getReportEventsCount,
  getReportId,
  getReportStatus,
} from "src/api/useReport.getters";
import { useReportsSelection } from "src/api/useReportsSelection";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { TextLineClampProps } from "src/components/TextLineClamp/TextLineClamp.model";
import { AppLink } from "src/components/AppLink/AppLink";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { KeyValue } from "src/components/KeyValue/KeyValue";
import { publicReportRoute } from "src/pages/PublicReport/PublicReport.route";
import { ReportThumbnail } from "src/components/ReportThumbnail/ReportThumbnail";
import { ArchivedReportIcon } from "src/components/icons/ArchivedReport";
import { ReportItemActions } from "../ReportItemActions/ReportItemActions";
import { ReportLink } from "../ReportLink";

export function ReportsTile({
  value,
  width = "100%",
  height = "100%",
  lineClamp = 2,
  isChecked,
}: {
  value: ReportItem;
  isChecked: boolean;
  width?: BoxProps["width"];
  height?: BoxProps["width"];
  lineClamp?: TextLineClampProps["lineClamp"];
}) {
  const { palette } = useTheme();
  const reportsSelection = useReportsSelection();

  const isReportReady = getReportStatus(value) === "ready";

  const handleChange = (checked: boolean) => {
    if (checked) {
      reportsSelection.select(value);
    } else {
      reportsSelection.unselect(value);
    }
  };

  const isAutomatedReport = value.type === "scheduled" && value.archiveId;

  return (
    <Stack
      border={isAutomatedReport ? "none" : `1px solid ${palette.divider}`}
      borderRadius={2}
      width={width}
      height={height}
      overflow="hidden"
      sx={{
        padding: isAutomatedReport ? "1px" : 0,
        position: "relative",
        "&::before": isAutomatedReport
          ? {
              content: '""',
              position: "absolute",
              inset: 0,
              borderRadius: "8px",
              padding: "1px",
              background:
                "linear-gradient(30deg, rgb(191, 127, 247), rgb(31, 108, 179), rgb(31, 108, 179))",
              WebkitMask:
                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);",
              mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);",
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
              pointerEvents: "none",
            }
          : null,
      }}
    >
      <Stack
        sx={{
          backgroundColor: palette.background.paper,
          width: "100%",
          height: "100%",
          borderRadius: "8px",
        }}
      >
        <Box position="relative">
          <ReportThumbnail
            reportId={getReportId(value)}
            width="100%"
            aspectRatio="16/9"
            alt={`Video thumbnail of ${getReportTitle(value)}`}
            type="tile"
            eventType={EventType.Report}
          />
          <TileCheckBox
            value={isChecked}
            onChange={handleChange}
            disabled={!isReportReady}
          />
          {isAutomatedReport && (
            <Tooltip title="This Report was generated automatically">
              <Box
                data-intercom-archived-icon-id="archived-icon-id"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <ArchivedReportIcon />
              </Box>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Stack
          pl={2}
          py={2}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row">
              <ReportsIconSmall isDisabled={!isReportReady} />
              {isReportReady ? (
                <AppLink
                  to={publicReportRoute.makeUrl({
                    reportId: getReportId(value),
                  })}
                  target="_blank"
                  rel="noopener"
                >
                  <TextLineClamp
                    variant="subtitle1"
                    color="primary"
                    lineClamp={1}
                    ml={1}
                  >
                    {getReportTitle(value)}
                  </TextLineClamp>
                </AppLink>
              ) : (
                <TextLineClamp
                  variant="subtitle1"
                  color={palette.text.disabled}
                  lineClamp={1}
                  ml={1}
                >
                  {getReportTitle(value)}
                </TextLineClamp>
              )}
            </Stack>
            <ReportItemActions value={value} />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            mb={1}
            pr={2}
            sx={{ color: isReportReady ? "inherit" : palette.text.disabled }}
          >
            <DateRange
              sx={{
                color: isReportReady
                  ? palette.action.active
                  : palette.text.disabled,
              }}
            />
            <Typography variant="body2" ml={1} mr={2}>
              {getReportDateTime(value).toLocaleString()}
            </Typography>
            <KeyValue name="Events:" value={getReportEventsCount(value)} />
          </Stack>
          <ReportLink
            reportId={getReportId(value)}
            reportStatus={getReportStatus(value)}
          />
        </Stack>
        {getReportDescription(value) && (
          <TranscriptBlock borderRadius={1} mx={2} mb={2} px={2} py={1}>
            <TextLineClamp
              fontSize={14}
              lineClamp={lineClamp}
              sx={{ color: isReportReady ? "inherit" : palette.text.disabled }}
            >
              {getReportDescription(value)}
            </TextLineClamp>
          </TranscriptBlock>
        )}
      </Stack>
    </Stack>
  );
}
