import {
  Box,
  Divider,
  ListItemSecondaryAction,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { ReportsIconSmall } from "src/components/icons/ReportsIconSmall";
import { ListBaseItem } from "src/components/ListBase/ListBaseItem";
import { KeyValue } from "src/components/KeyValue/KeyValue";
import { AppLink } from "src/components/AppLink/AppLink";
import { TextLineClamp } from "src/components/TextLineClamp/TextLineClamp";
import { TranscriptBlock } from "src/components/TranscriptBlock/TranscriptBlock";
import { TileCheckBox } from "src/components/TileCheckBox/TileCheckBox";
import { ReportItem } from "src/models/Report";
import { EventType } from "src/models/EventType";
import {
  getReportTitle,
  getReportDateTime,
  getReportDescription,
  getReportEventsCount,
  getReportId,
  getReportStatus,
} from "src/api/useReport.getters";
import { ReportThumbnail } from "src/components/ReportThumbnail/ReportThumbnail";
import { publicReportRoute } from "src/pages/PublicReport/PublicReport.route";
import { useReportsSelection } from "src/api/useReportsSelection";
import { ArchivedReportIcon } from "src/components/icons/ArchivedReport";
import { ReportItemActions } from "../ReportItemActions/ReportItemActions";
import { ReportLink } from "../ReportLink";

export function ReportsListItem({
  value,
  isChecked,
}: {
  value: ReportItem;
  isChecked: boolean;
}) {
  const { palette, spacing } = useTheme();
  const reportsSelection = useReportsSelection();

  const isReportReady = getReportStatus(value) === "ready";

  const handleChange = (checked: boolean) => {
    if (checked) {
      reportsSelection.select(value);
    } else {
      reportsSelection.unselect(value);
    }
  };

  const isAutomatedReport = value.type === "scheduled" && value.archiveId;

  return (
    <ListBaseItem
      key={value.id}
      sx={{
        maxHeight: 197,
        minHeight: 197,
        padding: 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="stretch"
        columnGap={2}
        sx={{
          backgroundColor: palette.background.paper,
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          padding: 2,
          position: "relative",
          "&::before": isAutomatedReport
            ? {
                content: '""',
                position: "absolute",
                inset: 0,
                borderRadius: "6px",
                padding: "1px",
                background:
                  "linear-gradient(11deg, rgb(191, 127, 247), rgb(31, 108, 179), rgb(31, 108, 179))",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);",
                mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
                pointerEvents: "none",
              }
            : null,
        }}
      >
        <Box position="relative" borderRadius={2} overflow="hidden">
          <ReportThumbnail
            reportId={getReportId(value)}
            height={165}
            alt={`Thumbnail of ${getReportTitle(value)}`}
            type="compact"
            eventType={EventType.Report}
          />
          <TileCheckBox
            value={isChecked}
            onChange={handleChange}
            disabled={!isReportReady}
          />
          {isAutomatedReport && (
            <Tooltip title="This Report was generated automatically">
              <Box
                data-intercom-archived-icon-id="archived-icon-id"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <ArchivedReportIcon />
              </Box>
            </Tooltip>
          )}
        </Box>

        <Stack flex={1} overflow="hidden">
          {/* header card */}
          <Stack direction="row" spacing={2} mr={12} mb={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <ReportsIconSmall isDisabled={!isReportReady} />
              {isReportReady ? (
                <AppLink
                  to={publicReportRoute.makeUrl({
                    reportId: getReportId(value),
                  })}
                  target="_blank"
                  rel="noopener"
                >
                  <TextLineClamp
                    variant="subtitle1"
                    color="primary"
                    lineClamp={1}
                  >
                    {getReportTitle(value)}
                  </TextLineClamp>
                </AppLink>
              ) : (
                <TextLineClamp
                  variant="subtitle1"
                  lineClamp={1}
                  color={palette.text.disabled}
                >
                  {getReportTitle(value)}
                </TextLineClamp>
              )}
            </Stack>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              minWidth="fit-content"
            >
              <DateRange
                sx={{
                  color: isReportReady
                    ? palette.action.active
                    : palette.text.disabled,
                }}
              />
              <TextLineClamp
                variant="body2"
                lineClamp={1}
                color={isReportReady ? "inherit" : palette.text.disabled}
              >
                {getReportDateTime(value)}
              </TextLineClamp>
            </Stack>
          </Stack>

          {/* attributes */}
          <Stack
            direction="row"
            columnGap={3}
            alignItems="center"
            maxWidth="100%"
            mb={2}
            sx={{ color: isReportReady ? "inherit" : palette.text.disabled }}
          >
            <KeyValue name="Events:" value={getReportEventsCount(value)} />
            <ReportLink
              reportId={getReportId(value)}
              reportStatus={getReportStatus(value)}
            />
          </Stack>

          {getReportDescription(value) && (
            <TranscriptBlock px={2} py={1} borderRadius={1}>
              <TextLineClamp
                fontSize={14}
                lineClamp={3}
                sx={{
                  color: isReportReady ? "inherit" : palette.text.disabled,
                }}
              >
                {getReportDescription(value)}
              </TextLineClamp>
            </TranscriptBlock>
          )}
        </Stack>
        <ListItemSecondaryAction
          sx={{ top: spacing(1), right: 8, transform: "none" }}
        >
          <ReportItemActions value={value} />
        </ListItemSecondaryAction>
      </Stack>
    </ListBaseItem>
  );
}
