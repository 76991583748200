import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useIsMobile } from "src/utils/useIsMobile";
import { useUserInviteList } from "src/api/useUserInviteList";
import { useUserInviteCreate } from "src/api/useUserInviteCreate";
import { useListFilter } from "src/utils/useListFilter";
import { SearchInput } from "src/components/SearchInput";
import { UserInviteTabs } from "../UserInviteTabs/UserInviteTabs";
import { UserInviteForm } from "../UserInviteForm/UserInviteForm";
import { UserInviteFormValues } from "../UserInviteForm/UserInviteForm.model";
import { userInviteSchema } from "../UserInviteForm/UserInviteForm.schema";
import { UserInviteTable } from "../UserInviteTable/UserInviteTable";

export function UserInviteDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const isMobile = useIsMobile();
  const { palette } = useTheme();
  const [filterText, setFilterText] = useState("");

  const formHook = useForm<UserInviteFormValues>({
    resolver: joiResolver(userInviteSchema),
    mode: "onChange",
    defaultValues: {
      emails: [],
      comment: "",
    },
  });

  const { handleSubmit, reset } = formHook;

  const inviteData = useUserInviteList();
  const inviteCreate = useUserInviteCreate({ onSuccess: () => reset() });

  const onSubmit = handleSubmit(
    (values) => {
      inviteCreate.mutate({
        body: {
          comment: values.comment,
          emailAddresses: values.emails,
        },
      });
    },
    (errors) => {
      console.error(errors);
    }
  );

  const invites = useListFilter(inviteData.data || [], filterText, [
    "comment",
    "email",
    "status",
  ]);

  useEffect(() => {
    if (!open) {
      // clear form when dialog is closed
      reset();
    }
  }, [open, reset]);

  if (isMobile) {
    return (
      <FormProvider {...formHook}>
        <Dialog open={open} onClose={onClose} fullScreen>
          <Stack direction="row">
            <IconButton
              aria-label="close"
              onClick={onClose}
              children={<Close sx={{ color: "text.primary" }} />}
              sx={{ width: 48, height: 48 }}
            />
            <DialogTitle sx={{ pl: 0, pr: 2, py: 1 }}>
              Invite User(s)
            </DialogTitle>
          </Stack>

          <UserInviteTabs
            invites={inviteData.data ?? []}
            isLoading={inviteData.isFetching}
            isSubmitting={inviteCreate.isLoading}
          />
          <Divider />

          <DialogActions sx={{ px: 2, py: 1 }}>
            <Button fullWidth variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={onSubmit}
              data-intercom-id-invite-send-button="invite-send-button"
              disabled={!formHook.formState.isValid || inviteCreate.isLoading}
            >
              {inviteCreate.isLoading ? <CircularProgress size={24} /> : "Send"}
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    );
  }

  return (
    <FormProvider {...formHook}>
      <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
        <DialogTitle
          columnGap={2}
          sx={{
            px: 3,
            py: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            All Invites{" "}
            <span data-intercom-invite-dialog-title-id="intercom-invite-dialog-title" />
          </Box>
          <Stack mt={2} width={240}>
            <SearchInput fullWidth onTextChangeThrottled={setFilterText} />
          </Stack>
        </DialogTitle>

        <Stack minHeight={400} px={3} mb={2}>
          <UserInviteTable
            rows={invites}
            isLoading={inviteData.isFetching || inviteCreate.isLoading}
            showBorder
          />
        </Stack>

        <Divider />

        <Stack px={3} pt={2} gap={2} bgcolor={[palette.background.default]}>
          <Typography variant="h6">
            Invite User(s){" "}
            <span data-intercom-invite-dialog-second-title-id="intercom-invite-dialog-second-title" />
          </Typography>
          <Typography>
            Send an invitation to your colleague to create an account. The
            invite will expire in 13 days, so act fast!
          </Typography>
          <UserInviteForm isDisabled={inviteCreate.isLoading} />
        </Stack>

        <DialogActions
          sx={{
            px: 3,
            pt: 0,
            pb: 1.5,
            justifyContent: "flex-end",
            bgcolor: palette.background.default,
          }}
        >
          <Button variant="outlined" onClick={onClose} sx={{ minWidth: 88 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={!formHook.formState.isValid || inviteCreate.isLoading}
            data-intercom-id-invite-send-button="invite-send-button"
            sx={{ minWidth: 88 }}
          >
            {inviteCreate.isLoading ? <CircularProgress size={24} /> : "Send"}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
