import { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
  buttonBaseClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { timezoneData } from "src/utils/timezoneData";
import { useOpenState } from "src/utils/useOpenState";
import { getSelectedRoles } from "src/utils/useUserRoleList";
import { User } from "src/pages/Settings/Settings.model";
import { useManagementView } from "src/api/useManagementView";
import { useManagementViewUpdate } from "src/api/useManagementViewUpdate";
import { UserInformationUpdateDialog } from "../UserInformationUpdateDialog/UserInformationUpdateDialog";
import { UserInformationSettingsFormValues } from "./UserInformationSettingsForm.model";
import { TextInputBase } from "../TextInputBase";
import { UserInformationSettingsFormSchema } from "./UserInformationSettingsForm.schema";
import { LoadingButton } from "../buttons/LoadingButton";
import { UserRolePicker } from "../UserRolePicker/UserRolePicker";
import { UserInviteDialog } from "../../pages/PublicUserInvite/components/UserInviteDialog/UserInviteDialog";
import { AccountSeatsInfoBar } from "./components/AccountSeatsInfoBar";

export function UserInformationSettingsForm({
  loading,
  userInfo,
  isRegularUser,
}: {
  loading?: boolean;
  userInfo?: User;
  isRegularUser?: boolean;
}) {
  const { data } = useManagementView();
  const inviteDialog = useOpenState();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = useMemo(
    () => ({
      timezone: data?.timezone || "",
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      email: data?.email || "",
      phoneNumber: data?.phone || "",
      trackLocation: data?.trackLocation || false,
      account: data?.accountName || "",
      roles: data?.roles || [],
    }),
    [data]
  );

  const { breakpoints } = useTheme();
  const [userCreatedDate, setUserCreatedDate] = useState<Date>();
  const updateInfoDialog = useOpenState();

  const [userExpirationDate, setUserExpirationDate] = useState<Date>();
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const { control, reset, formState, handleSubmit, setValue, watch } =
    useForm<UserInformationSettingsFormValues>({
      mode: "all",
      resolver: joiResolver(UserInformationSettingsFormSchema),
      defaultValues: initialFormValues,
    });
  const selectedRoles = watch("roles") || [];

  const managementViewUpdate = useManagementViewUpdate({
    options: {
      onSuccess: () => {
        enqueueSnackbar(
          t("managementViewUpdateMessage", "User information saved"),
          {
            variant: "success",
          }
        );
      },
    },
  });

  const handleSubmitWrapper = handleSubmit(
    (formData) => {
      managementViewUpdate.mutate({
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        email: formData?.email,
        phone: formData?.phoneNumber,
        accountName: data?.accountName,
        timezone: formData?.timezone,
        trackLocation: formData?.trackLocation,
        roles: formData?.roles,
      });
    },
    (errors) => {
      console.log("@@ DEBUG:UserInformationSettingsForm:error", errors);
    }
  );

  const handleRolesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (selectedRoles?.includes(value)) {
      const newRoles = selectedRoles.filter((v) => v !== value);
      setValue("roles", newRoles);
    } else if (selectedRoles) {
      const roles = getSelectedRoles(value);
      setValue("roles", roles);
    }
  };

  useEffect(() => {
    reset(initialFormValues);
    const createdDateFormatted = new Date(data?.created || "");
    const expirationDateFormatter = new Date(data?.expiration || "");
    setUserCreatedDate(createdDateFormatted);
    setUserExpirationDate(expirationDateFormatter);
    if (data?.roles) {
      setValue("roles", data?.roles);
    }
  }, [userInfo, reset, initialFormValues, data, setValue]);

  const isBusy = formState.isSubmitting || managementViewUpdate.isLoading;
  const isDisabled = !formState.isValid || !formState.isDirty;

  const userData = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2}>
      <Grid
        item
        xs={12}
        md={12}
        mt={isMobile ? 3 : 4}
        pt={4}
        pb={isMobile ? 1 : 0}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Typography variant="subtitle1" pl={2} pt={1}>
          User Information
        </Typography>
        {isRegularUser && (
          <Button
            variant="text"
            onClick={updateInfoDialog.show}
            sx={{ marginRight: 4, paddingTop: 1 }}
          >
            Update info
          </Button>
        )}
      </Grid>

      <Grid
        container
        spacing={isMobile ? 1.5 : 3}
        mb={1}
        m={2}
        rowSpacing={1}
        sx={{ "& .Mui-disabled fieldset": { borderStyle: "dotted" } }}
      >
        <Grid item xs={12} md={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="First name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isRegularUser}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Last name"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isRegularUser}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Email"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || " "}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Phone"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isRegularUser}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Controller
            name="account"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextInputBase
                  label="Account"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={true}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="timezone"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel>Time Zone</InputLabel>
                <Select
                  {...field}
                  id="timezone"
                  label="Time Zone"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={loading}
                  error={!!fieldState.error}
                  fullWidth
                  MenuProps={{
                    style: {
                      maxHeight: 300,
                      width: 100,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  }}
                  inputProps={{
                    sx: {
                      display: "grid",
                    },
                  }}
                >
                  {timezoneData.map((timezone) => (
                    <MenuItem key={timezone.Id} value={timezone.Id}>
                      <Typography
                        variant="inherit"
                        sx={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {timezone.Display}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const accountData = (
    <Grid
      container
      spacing={isMobile ? 1.5 : 3}
      mb={2}
      sx={{ "& .Mui-disabled fieldset": { borderStyle: "dotted" } }}
    >
      <Grid container spacing={isMobile ? 1.5 : 3} mb={1} mx={2} rowSpacing={1}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Creation date</Typography>
          <DateTimePicker
            sx={{
              marginTop: 2,
            }}
            value={userCreatedDate || null}
            disabled={true}
            format="MM/dd/yyyy"
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "outlined",
              },
            }}
            slots={{
              textField: TextInputBase,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Expiration date</Typography>
          <DateTimePicker
            sx={{
              marginTop: 2,
            }}
            value={userExpirationDate || null}
            disabled={true}
            format="MM/dd/yyyy"
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "outlined",
              },
            }}
            slots={{
              textField: TextInputBase,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const location = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2} pl={2}>
      <Grid item xs={12} md={12} lg={6}>
        <Typography variant="subtitle1">Location</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Controller
                name="trackLocation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} />
                )}
              />
            }
            label="Keep track of my current location"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Typography variant="subtitle1">
          Number of seats in my account
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mr={2}
          height={38}
        >
          <AccountSeatsInfoBar />
          <Button
            variant="text"
            children="Invite User"
            data-intercom-id-invite-button-="open-invite-dialog-button"
            onClick={inviteDialog.show}
          />
          <UserInviteDialog
            open={inviteDialog.isOpen}
            onClose={inviteDialog.hide}
          />
        </Stack>
      </Grid>
    </Grid>
  );

  const roles = (
    <Grid container spacing={isMobile ? 1.5 : 3} mb={2} pl={2}>
      <Grid item xs={12} md={12}>
        <UserRolePicker value={selectedRoles} onChange={handleRolesChange} />
      </Grid>
    </Grid>
  );

  return (
    <Stack ref={containerRef} px={isMobile ? 0 : 2}>
      {userData}
      {accountData}
      {location}
      {!isRegularUser && roles}
      <Grid container spacing={isMobile ? 1.5 : 3} mb={2} pl={2}>
        <Grid item xs={12} md={12} display="flex" justifyContent="end" mr={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              minWidth: isMobile ? 160 : 176,
              height: 42,
              width: isMobile ? "100%" : 176,
              [`.${buttonBaseClasses.root}`]: { marginLeft: "8px" },
            }}
            loading={isBusy}
            disabled={isDisabled}
            onClick={handleSubmitWrapper}
          >
            Save changes
          </LoadingButton>
        </Grid>
      </Grid>
      <UserInformationUpdateDialog
        title="Request user information update"
        open={updateInfoDialog.isOpen}
        onClose={updateInfoDialog.hide}
        user={data}
      />
    </Stack>
  );
}
